import React from 'react'
import styles from './Design.module.css'

const Design = () => (
  <div className={styles.base}>
    <div className={styles.one} />
    <div className={styles.two} />
    <div className={styles.three} />
    <div className={styles.four} />
  </div>
)

export default Design
