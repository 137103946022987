import React from 'react'
import styles from './Footer.module.css'

function Footer() {
  const year = new Date().getFullYear()

  return (
    <footer className={styles.base}>
      <span className={styles.copyright}>Steve Holgado &copy; {year}</span>
    </footer>
  )
}

export default Footer
