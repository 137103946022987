import React from 'react'

function IconEmail({ size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      width={size || 28}
      height={size || 28}
    >
      <path
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="3"
        d="M86.785 109.878c-8.188 4.317-16.533 5.962-26.515 5.962-24.428 0-45.133-17.879-45.133-46.479 0-30.687 21.299-57.201 54.376-57.201 25.918 0 43.348 18.175 43.348 43.052 0 22.342-12.517 35.448-26.514 35.448-5.968 0-11.475-4.021-11.025-13.105h-.594C69.514 86.342 62.66 90.66 53.721 90.66c-8.636 0-16.083-7-16.083-18.764 0-18.473 14.591-35.309 35.296-35.309 6.403 0 12.067 1.34 15.937 3.13L83.813 66.68c-2.232 11.323-.45 16.532 4.463 16.685 7.604.146 16.095-9.982 16.095-27.261 0-21.602-12.964-37.09-36.06-37.09-24.27 0-44.684 19.212-44.684 49.456 0 24.877 16.241 40.215 38.28 40.215 8.491 0 16.387-1.783 22.499-5.21l2.379 6.403zm-8.187-64.351c-1.493-.449-4.027-1.043-7.446-1.043-13.112 0-23.689 12.366-23.689 26.812 0 6.556 3.275 11.322 9.836 11.322 8.637 0 16.532-11.025 18.169-20.256l3.13-16.835z"
      />
    </svg>
  )
}

export default IconEmail
