import React from 'react'
import Brand from '../Brand'
import Profiles from '../Profiles'
import styles from './Header.module.css'

function Header({ title }) {
  return (
    <header className={styles.base}>
      <Brand title={title} />
      <Profiles />
    </header>
  )
}

export default Header
