import React, { Fragment } from 'react'
import styles from './Details.module.css'

function Details({ date, tags = [] }) {
  return (
    <p className={styles.base}>
      <code className={styles.date + ' language-text'}>{date}</code>
      {tags && tags.map((tag, i) => (
        <Fragment key={i}>
          {tag}
          {i < tags.length - 1 && <span className={styles.divider}> / </span>}
        </Fragment>
      ))}
    </p>
  )
}

export default Details
