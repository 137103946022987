import React from 'react'
import IconGitHub from '../IconGitHub'
import IconStackOverflow from '../IconStackOverflow'
import IconEmail from '../IconEmail'
import styles from './Profiles.module.css'

function scrollToBio() {
  const bio = document.querySelector('#bio')
  if (!bio) return

  window.scrollTo(0, bio.offsetTop)
}

function Profiles() {
  return (
    <div className={styles.base}>
      <button
        className={styles.contact}
        onMouseDown={e => e.preventDefault()}
        onClick={scrollToBio}
      >
        Contact
      </button>
      <ul className={styles.items}>
        <li className={styles.item}>
          <a
            href="https://github.com/steveholgado"
            title="GitHub"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconGitHub />
          </a>
        </li>
        <li className={styles.item}>
          <a
            href="https://stackoverflow.com/users/7838401/steve-holgado"
            title="StackOverflow"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconStackOverflow />
          </a>
        </li>
        <li className={styles.item + ' ' + styles.email}>
          <a
            href="mailto:steveholgado@gmail.com"
            title="Email: steveholgado@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconEmail />
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Profiles
