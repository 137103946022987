import React from 'react'
import { Link } from 'gatsby'
import styles from './Brand.module.css'

function Brand({ title }) {
  return (
    <h1 className={styles.base}>
      <Link className={styles.link} to={`/`}>
        {title}
      </Link>
    </h1>
  )
}

export default Brand
