import React from 'react'
import JSLogo from '../IconJSLogo'
import styles from './Bio.module.css'

function Bio() {
  return (
    <section className={styles.base} id="bio">
      <div className={styles.logo}>
        <JSLogo size={64} />
      </div>
      <p>
        Hi, I'm a Senior Front-End Developer based in London. Feel free to contact me with questions, opportunities, help with open source projects, or anything else :)
        <br /><br/>
        You can find me on{' '}
        <a
          href="https://github.com/steveholgado"
          target="_blank"
          rel="noopener noreferrer"
        >
          GitHub
        </a>
        ,{' '}
        <a
          href="https://stackoverflow.com/users/7838401/steve-holgado"
          target="_blank"
          rel="noopener noreferrer"
        >
          Stack Overflow
        </a>{' '}
        or{' '}
        <a
          href="mailto:steveholgado@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          email me directly
        </a>.
      </p>
    </section>
  )
}

export default Bio
